import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { RiWhatsappFill } from 'react-icons/ri';
import { useToast } from '../../../contexts/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/NewInput';
import { api } from '../../../services/api';

import {
  ActivityIndicator, Container,
  Content,
  ContinueButton,
  FormWrapper,
  StyledForm,
  TitleContent
} from './styles';


interface FormData {
  name: string;
  phone: string;
}
export const SupporUserForm = () => {
  const { addToast } = useToast();
  const { type } = useParams<{ type: string }>();
  const viewRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);


 const handleSubmit = useCallback(
    async (formData: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é um campo obrigatório'),
          phone: Yup.string().required('Número de contato obrigatório')
        
        });

        await schema.validate(formData, { abortEarly: false });

        setLoading(true);
        const response = await api.post(
          `support/user`,
          {
            name: formData.name,
            phone: formData.phone,
          },
        );
        setLoading(false);

        if (response.status === 201) {
          addToast({
            type: 'info',
            title: 'Suporte Whatsapp',
            description: 'Dados enviados com sucesso!',
            linkURL: 'https://api.whatsapp.com/message/6UNFSKKR423NO1?autoload=1&app_absent=0'
          });
          setTimeout(()=>{
            window.location.href = "https://api.whatsapp.com/message/6UNFSKKR423NO1?autoload=1&app_absent=0";
          },1500)
          setIsRegistrationCompleted(true);
        }
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao comunicar com Servidor',
        });
      }
    },
    [addToast, type],
  );

  return (
    <Container className="bg">
      <Content className="formContainer center">
        <FormWrapper ref={viewRef} isCompleted={loading}>
          <TitleContent>
            <i><RiWhatsappFill size={38} /></i>
            <h1>Fale conosco no  Whatsapp!</h1>
          </TitleContent>
          <StyledForm
            ref={formRef}
            onSubmit={formData => handleSubmit(formData)}
          >
            <div>
              <Input 
                name="name" 
                placeholder="Nome"
                dark
              />
            </div>
            <div>            
              <Input
                name="phone"
                placeholder=" DDD + Celular"
                mask="(99) 99999-9999"
                dark
              />
            </div>
            <ContinueButton
              disabled={
              loading ||
              isRegistrationCompleted}
              type="submit"
            >
              {loading && (
                <ActivityIndicator>
                  <AiOutlineLoading3Quarters />
                </ActivityIndicator>
              )}
              {!loading &&
                  (type === 'iniciar'
                    ? 'Começar conversa'
                    : 'Iniciar conversa')}
            </ContinueButton>
          </StyledForm>
        </FormWrapper>
      </Content>
    </Container>
  );
};
